import React, { useState, useRef, useEffect } from 'react';
import { AlertProps, Form } from 'react-bootstrap';
import { Alert, AlertColor, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import '../../styles/Login.css';
// import paratChat from '../../images/parat-logo.svg';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { authStore } from '../../App';
import { AuthService } from '../../services/AuthService';
import { useApi } from '../../services/HttpService';
import { Box, useTheme } from '@mui/material';
import FallbackImage from '../../components/file/FallbackImage';

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<AlertColor | undefined>('error');
  const [validated, setValidated] = useState(false);
  const [viewState, setViewState] = useState(1);
  const [userLoginId, setUserLoginId] = useState<number | null>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { formstep } = useParams();
  const theme = useTheme();

  const { api: loginApi, response: loginResponse, isLoading: loginIsLoading, error: loginError } = useApi();
  const { api: validateApi, response: validateResponse, isLoading: validateIsLoading, error: validateError } = useApi();
  const { authenticate, login } = AuthService();


  useEffect(() => {
    let step = 0;
    if (formstep && !isNaN(parseInt(formstep))) {
      let parsed = parseInt(formstep);
      if (parsed >= 1 && parsed <= 3) {
        step = parseInt(formstep);
      }
    }
    if (step === 0) {
      setViewStep(1);
    }
    if (step === 2 && !userLoginId) {
      setViewStep(1);
    }
    setViewState(step);
  }, [formstep]);

  const setViewStep = (num: number) => {
    if (num >= 1 && num <= 3) {
      setMessage('');
      navigate('/login/' + num);
    }
  }

  const setDisplayMessage = (message: string, type: AlertColor = 'error') => {
    setMessage(message);
    setMessageType(type);
  }

  const sendNewCode = async (e: any) => {
    e.preventDefault()
    setIsloading(true);
    await timeout(1000);
    setDisplayMessage("Ny kode er sendt!", "success");
    setIsloading(false);
  }

  useEffect(() => {
    if (loginResponse) {
      setViewStep(2);
      setUserLoginId(loginResponse?.id);
      setValidated(false);
    }
  }, [loginResponse])

  useEffect(() => {
    if (loginError) {
      if (passRef.current != null) {
        passRef.current.select();
      }
      loginError.toLowerCase().includes("noe gikk galt") ?
        setDisplayMessage(loginError) :
        setDisplayMessage("Feil brukernavn eller passord")
    }
  }, [loginError]);

  useEffect(() => {
    if (validateResponse) {
      login(validateResponse?.tokens).then(success => {
        if (success) {
          authenticate().then(success => {
            if (success) {
              navigate("/home");
            }
          });
        }
      });
    }
  }, [validateResponse]);

  useEffect(() => {
    if (validateError) {
      if (codeRef.current != null) {
        codeRef.current.select();
      }
      loginError.toLowerCase().includes("noe gikk galt") ?
        setDisplayMessage(loginError) :
        setDisplayMessage("Feil kode")
    }
  }, [validateError]);

  const submit = async (event: any) => {
    setIsloading(true);
    setDisplayMessage('');
    setValidated(true);

    let forms = event.currentTarget
    let form = new FormData(forms);

    event.preventDefault();
    if (forms.checkValidity() === false) {
      event.stopPropagation();
      setIsloading(false);
      return;
    }

    if (viewState === 1) {
      // validate username password
      await loginApi("login", "POST", { identificator: username, password: password });
    }
    else if (viewState === 2) {
      // validate SMS code
      await validateApi("loginVerify", "POST", { code: code, id: userLoginId });
    }
    else if (viewState === 3) {
      const dataObject: any = {
        username: username
      };
      await timeout(1000);
      setValidated(false);
      setDisplayMessage("Email med tilbakestilling av brukernavn/passord ble sendt til " + username, "info");
      // setViewStep(1);
    }

    // for (let key of Array.from(form.keys())) {
    //   dataObject[key] = form.get(key);
    // };

    // const res = await fetch(process.env.REACT_APP_REMOTE_URL + '/auth/auth', { method: 'post', body: JSON.stringify(dataObject) });
    // const resObj = await res.json();
    // setIsloading(false)

    // if (resObj.error === false) {
    //   util.setRefreshToken(resObj.data.refresh_token);
    //   navigate('/home')
    // } else {
    //   // Set password field in focus
    //   setError(resObj.data.message);
    // }

    setIsloading(false);
  }

  return (
    <>
      <div id="login" style={{ margin: 'auto' }}>
        <div className="login_left"></div>
        <div className='logo_image'>
          <FallbackImage
            primarySrc="/api/static/logo"
            secondarySrc="/default/logo.png"
            altText="logo"
          />
        </div>
        <div className="login_right">
          {message && <div><br /><Alert severity={messageType}>{message}</Alert></div>}
          <div className="login">
            <Form onSubmit={submit} validated={validated} noValidate >
              {viewState === 1 &&
                <>
                  <Form.Group className="mb-3 form-item" controlId="formUsername">
                    <Form.Label style={{ fontFamily: "SourceHanSansCN-Regular", fontSize: '1.13rem' }}>BRUKERNAVN</Form.Label>
                    <div className="mb-3">
                      <span className="icons userIcon"> </span>
                      <Form.Control
                        name="username"
                        type="text"
                        autoComplete="email"
                        autoFocus
                        placeholder="Vennligst skriv inn brukernavn"
                        onChange={e => setUsername(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Vennligst skriv inn brukernavn.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3 form-item" controlId="formBasicPassword">
                    <Form.Label style={{ fontFamily: "SourceHanSansCN-Regular", fontSize: '1.13rem' }}>PASSORD</Form.Label>
                    <div className="mb-3">
                      <span className="icons passwrodIcon"> </span>
                      <Form.Control
                        ref={passRef}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Skriv inn passord"
                        onChange={e => setPassword(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Skriv inn passord.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Link underline="always" sx={{ color: theme.palette.text.primary, cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setViewStep(3) }}>
                    Glemt brukernavn / password
                  </Link>
                </>
              }
              {viewState === 3 &&
                <>
                  <Form.Group className="mb-3 form-item" controlId="formUsername">
                    <Form.Label style={{ fontFamily: "SourceHanSansCN-Regular", fontSize: '1.13rem' }}>EMAIL</Form.Label>
                    <div className="mb-3">
                      <span className="icons"><EmailOutlinedIcon /></span>
                      <Form.Control
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        placeholder="Vennligst skriv inn din emailadresse"
                        onChange={e => setUsername(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Vennligst skriv inn email.
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Link underline="always" sx={{ color: theme.palette.text.primary, cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setViewStep(1) }}>
                    Logg Inn
                  </Link>
                </>
              }
              <div className="loginButton">
                {viewState === 2 &&
                  <div style={{ width: "50%" }}>
                    <Form.Group className="mb-3 form-item" controlId="formCode">
                      <Form.Label style={{ fontFamily: "SourceHanSansCN-Regular", fontSize: '1.13rem' }}>KODE</Form.Label>
                      <div className="mb-3">
                        <span className="icons codeIcon"> </span>
                        <Form.Control
                          ref={codeRef}
                          name="code"
                          type="text"
                          autoComplete=""
                          placeholder="Skriv inn kode"
                          onChange={e => setCode(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Skriv inn kode som er sendt på SMS.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                }
                <Form.Group className="mb-3 form-item" controlId="formUsername" style={{ width: (viewState === 2) ? "46%" : "100%" }}>
                  <Form.Label style={{ fontFamily: "SourceHanSansCN-Regular", fontSize: '1.13rem' }}>&nbsp;</Form.Label>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                      textTransform: 'none',
                      fontSize: '1.5rem',
                      fontFamily: "SourceHanSansCN-Regular",
                      width: "100%",
                    }}
                    disabled={isloading}
                    endIcon={isloading && <CircularProgress />}
                  >
                    {viewState === 3 ? "Send" : "Logg Inn"}
                  </Button>
                </Form.Group>
              </div>
              {viewState === 2 && (
                <div className='forgotSomething'>
                  <Link underline="always" sx={{ color: theme.palette.text.primary, cursor: 'pointer' }} onClick={(e) => sendNewCode(e)}>
                    Send kode på nytt
                  </Link>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div >
    </>
  )
}