import { Alert, alpha, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, Paper, styled, Switch, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { treeItemClasses } from '@mui/x-tree-view';

interface Menuitem {
  description: string;
  id: number;
  level: number;
  license_paid: boolean;
  name: string;
  parent_id: number;
  priority: number;
  status: number;
  sys_form_id: number;
  updated_at: Date;
  updated_by: string;
  url: string;
  validated_at: Date;
  children: Menuitem[];
}

interface Accessgroup {
  id: number;
  name: string;
  parent_id: number;
  status: number;
  children: Accessgroup[];
}

interface AccessgroupMenuitem {
  id: number;
  name: string;
  accessgroup_id: number;
  sys_menuitem_id: number;
  status: number;
}

interface MenuSettings {
  fields: any;
  forms: any;
  menuitems: Menuitem[];
  accessgroups: Accessgroup[];
  accessgroupMenuitems: AccessgroupMenuitem[];
}

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0, 1.2),
    ...theme.applyStyles('light', {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    }),
    ...theme.applyStyles('dark', {
      color: theme.palette.primary.contrastText,
    }),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export const MenuitemSettings = () => {
  const theme = useTheme();
  const { api, error, isLoading } = useApi();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [menuSettings, setMenuSettings] = useState<MenuSettings>({} as MenuSettings);
  const [menuSettingsCopy, setMenuSettingsCopy] = useState<MenuSettings>({} as MenuSettings);
  const [menuitems, setMenuitems] = useState<Menuitem[]>([]);
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]);
  const [expandedMenuItems, setExpandedMenuItems] = useState<string[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<string>("");
  const [expandedAccessgroup, setExpandedAccessgroup] = useState<string[]>([]);
  const [hieraricalView, setHieraricalView] = useState<boolean>(true);
  const [selectedAccessgroups, setselectedAccessgroups] = useState<string[]>([]);


  useEffect(() => {
    api("/systemMenuItemSettings").then(res => {
      if (res.statusCode === 200) {
        setMenuSettings(res);
        setMenuSettingsCopy(res);
        setExpandedAccessgroup(res?.accessgroups?.map(accessgroup => accessgroup.name) || []);
      }
    });
  }, []);

  useEffect(() => {
    createMenuitemHirarchy();
    createAccessgroupHirarchy();
  }, [menuSettings]);

  const reset = () => {
    setMenuSettings(menuSettingsCopy);
  }

  const saveSettings = () => {
    setSuccessMessage("");
    api("/saveSystemMenuItemSettings", "post", menuSettings).then(res => {
      if (res.statusCode === 200) {
        setSuccessMessage("menu settings saved");
      }
    });
  }

  const createMenuitemHirarchy = () => {
    const menuItemMap = new Map<number, Menuitem>();

    // Initialize the map and set the children array for each item
    menuSettings?.menuitems?.forEach(item => {
      let children: Menuitem[] = [];
      item.children = children;
      menuItemMap.set(item.id, item);
    });

    const hierarchy: Menuitem[] = [];

    menuSettings?.menuitems?.forEach(item => {
      if (item.parent_id === 0) {
        // no parent
        hierarchy.push(item);
      } else {
        // Find the parent in the map and add the item to its children
        const parent = menuItemMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setMenuitems(hierarchy);
  }

  const createAccessgroupHirarchy = () => {
    const accessgroupMap = new Map<number, Accessgroup>();

    menuSettings?.accessgroups?.forEach(item => {
      let children: Accessgroup[] = [];
      item.children = children;
      accessgroupMap.set(item.id, item);
    });

    const hierarchy: Accessgroup[] = [];

    menuSettings?.accessgroups?.forEach(item => {
      if (item.parent_id === 0) {
        hierarchy.push(item);
      } else {
        const parent = accessgroupMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setAccessgroups(hierarchy);
  }

  const handleExpandedMenuItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setSelectedMenu("");
    setExpandedMenuItems(itemIds);
  };

  const handleExpandMenuClick = () => {
    setExpandedMenuItems((oldExpanded) =>
      oldExpanded.length === 0
        ? menuSettings?.menuitems?.map(menuitem => menuitem.name)
        : []
    );
  };

  const handleMenuSettingsClick = (event, menuName) => {
    event.stopPropagation();

    const menuId = menuSettings?.menuitems?.find(menuitem => menuitem.name === menuName)?.id;
    const selectedAccessgroupMenuitems = menuSettings?.accessgroupMenuitems?.filter(v => v.sys_menuitem_id === menuId);
    let selectedAccessgroups = selectedAccessgroupMenuitems.map(item => {
      return menuSettings?.accessgroups?.find(accessgroup => accessgroup.id === item.accessgroup_id)?.name
    })
    setselectedAccessgroups(selectedAccessgroups);

    setExpandedMenuItems((oldExpanded) => oldExpanded.filter((item) => item !== menuName));
    if (selectedMenu === menuName) {
      setSelectedMenu("");
      return;
    }
    setSelectedMenu(menuName);
  }

  const handleExpandedAccessgroupChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedAccessgroup(itemIds);
  };

  const handleExpandAccessgroupClick = () => {
    setExpandedAccessgroup((oldExpanded) =>
      oldExpanded.length === 0
        ? menuSettings?.menuitems?.map(menuitem => menuitem.name)
        : []
    );
  };

  const editAccessgroupMenuitems = (menuitem_id: number, accessgroup_id: number) => {
    console.log("triggered")
    let item = menuSettings?.accessgroupMenuitems?.find(v => {
      return v.accessgroup_id === accessgroup_id && v.sys_menuitem_id === menuitem_id
    });

    let updatedAccessgroupMenuitems: AccessgroupMenuitem[] = [];

    if (item) {
      // remove
      updatedAccessgroupMenuitems = menuSettings.accessgroupMenuitems.filter(
        v => !(v.accessgroup_id === accessgroup_id && v.sys_menuitem_id === menuitem_id)
      );
    } else {
      // check copy, add back or make new
      let newItem = menuSettingsCopy?.accessgroupMenuitems?.find(v => {
        return v.accessgroup_id === accessgroup_id && v.sys_menuitem_id === menuitem_id
      });

      if (!newItem) {
        newItem = {
          id: 0,
          name: "New Item",
          accessgroup_id: accessgroup_id,
          sys_menuitem_id: menuitem_id,
          status: 0
        }
      }

      updatedAccessgroupMenuitems = [...menuSettings.accessgroupMenuitems, newItem]
    }

    const selectedAccessgroupMenuitems = updatedAccessgroupMenuitems?.filter(v => v.sys_menuitem_id === menuitem_id);
    let selectedAccessgroups = selectedAccessgroupMenuitems.map(item => {
      return menuSettings?.accessgroups?.find(accessgroup => accessgroup.id === item.accessgroup_id)?.name
    })
    setselectedAccessgroups(selectedAccessgroups);

    setMenuSettings(prev => ({
      ...prev,
      accessgroupMenuitems: updatedAccessgroupMenuitems
    }));
  }

  const handleSelectedAccessgroupsChange = (itemNames: string[], menuitem_id: number) => {
    let itemIds = itemNames.map(name => {
      return menuSettings?.accessgroups?.find(accessgroup => accessgroup.name === name)?.id
    })

    // copy of accessgroupMenuitems, remove all items with menuitem_id
    let newItems: AccessgroupMenuitem[] = menuSettings?.accessgroupMenuitems.filter(v => !(v.sys_menuitem_id === menuitem_id));

    itemIds.forEach(accessgroup_id => {
      let newItem = menuSettingsCopy?.accessgroupMenuitems?.find(v => {
        return v.accessgroup_id === accessgroup_id && v.sys_menuitem_id === menuitem_id
      });

      if (!newItem) {
        newItem = {
          id: 0,
          name: "New Item",
          accessgroup_id: accessgroup_id,
          sys_menuitem_id: menuitem_id,
          status: 0
        }
      }

      newItems.push(newItem);
    });

    const selectedAccessgroupMenuitems = newItems.filter(v => v.sys_menuitem_id === menuitem_id);
    let selectedAccessgroups = selectedAccessgroupMenuitems.map(item => {
      return menuSettings?.accessgroups?.find(accessgroup => accessgroup.id === item.accessgroup_id)?.name
    })
    setselectedAccessgroups(selectedAccessgroups);

    setMenuSettings(prev => ({
      ...prev,
      accessgroupMenuitems: newItems
    }));
  }

  function recursiveAcessgroupTree(accessgroup: Accessgroup, menuitem: Menuitem) {
    return (
      <Box key={accessgroup.name}>
        <CustomTreeItem
          sx={{ width: '100%' }}
          itemId={accessgroup.name}
          label={accessgroup.name}
        >
          {accessgroup.children.map(child => recursiveAcessgroupTree(child, menuitem))}
        </CustomTreeItem>
      </Box>
    )
  }

  function recursiveMenuTree(menuitem: Menuitem) {
    return (
      <Box key={menuitem.name}>
        <CustomTreeItem
          sx={{ width: '100%' }}
          itemId={menuitem.name}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body1'>{menuitem.name}</Typography>
              <IconButton edge="end" size="small" onClick={(event) => handleMenuSettingsClick(event, menuitem.name)}>
                <ManageAccountsIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
              </IconButton>
            </Box>
          }
        >
          {menuitem.children.map(child => recursiveMenuTree(child))}
        </CustomTreeItem>

        {selectedMenu === menuitem.name &&
          <Paper elevation={4} sx={{ p: 1 }}>
            <Typography variant='h6' sx={{ mb: 1 }}>{selectedMenu} - Access</Typography>

            {hieraricalView ?
              <>
                {/** add select/deselect all children */}
                <Alert severity="error" sx={{ width: "100%", mb: 1 }}>Not working properly, unable to edit</Alert>
                <SimpleTreeView
                  multiSelect
                  checkboxSelection
                  selectedItems={selectedAccessgroups}
                  // onSelectedItemsChange={(e, accessgroupName) => handleSelectedAccessgroupsChange(accessgroupName, menuitem.id)} // not working properly
                  expandedItems={expandedAccessgroup}
                  onExpandedItemsChange={handleExpandedAccessgroupChange}
                >
                  {accessgroups?.map((accessgroup: Accessgroup) => (
                    recursiveAcessgroupTree(accessgroup, menuitem)
                  ))}
                </SimpleTreeView>
              </> :
              <>
                {menuSettings?.accessgroups?.map((accessgroup, index) => {
                  let selected = menuSettings?.accessgroupMenuitems?.findIndex(v => {
                    return v.accessgroup_id === accessgroup.id && v.sys_menuitem_id === menuitem.id
                  })

                  return (
                    <FormGroup key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selected >= 0}
                            disabled={accessgroup.name === "admin"}
                            onChange={() => editAccessgroupMenuitems(menuitem.id, accessgroup.id)}
                            sx={{ p: 0, pr: 1, ml: 2 }}
                          />}
                        label={accessgroup.name}
                      />
                    </FormGroup>
                  )
                })}
              </>
            }
          </Paper>
        }
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <Typography variant='h5' sx={{ mt: 2 }}>Menu settings</Typography>

      <InfoArea title='Access groups'>
        {menuSettings?.accessgroups?.map((accessgroup, index) => (
          <Typography key={index} variant='body1' sx={{ mt: 2 }}>{accessgroup.name}</Typography>
        ))}
      </InfoArea>

      <InfoArea title='Menu items'>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Button variant='outlined' onClick={handleExpandMenuClick} sx={{ width: 150, fontWeight: "bold" }}>
            {expandedMenuItems.length === 0 ? 'Expand all' : 'Collapse all'}
          </Button>
          <FormGroup sx={{ width: 170 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hieraricalView}
                  onChange={(e) => setHieraricalView(e.target.checked)}
                />}
              label={hieraricalView ? "Hierarchy view" : "Flat view"}
            />
          </FormGroup>
        </Box>
        <SimpleTreeView
          expandedItems={expandedMenuItems}
          onExpandedItemsChange={handleExpandedMenuItemsChange}
        >
          {menuitems?.map((menuitem: Menuitem) => (
            recursiveMenuTree(menuitem)
          ))}
        </SimpleTreeView>
      </InfoArea>

      {successMessage && <Alert severity="success" sx={{ width: "100%" }}>{successMessage}</Alert>}
      {error && <Alert severity="error" sx={{ width: "100%" }}>{error}</Alert>}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Button variant='contained' color='darkgrey' onClick={reset}>Reset</Button>
          <Button
            variant='contained'
            color='info'
            onClick={saveSettings}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={22} />}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}