/**
 * Usage:
 * 
 * import localStorageService, { LocalStorageKeys } from './path/to/localStorageService';
 * localStorageService.setItem(LocalStorageKeys.USER, { name: 'John Doe', age: 30 });
 * const user = localStorageService.getItem(LocalStorageKeys.USER);
 * console.log(user); // { name: 'John Doe', age: 30 }
 * localStorageService.removeItem(LocalStorageKeys.USER);
 */



export enum LocalStorageKeys {
  USER = 'user',
  COLOR_MODE = 'color_mode', // "dark" | "light"
  THEME = 'theme', // "dark" | "light"
  TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
  DCP_TOKENS = 'dcp_tokens', // access_token, refresh_token
  DCP_USER = 'dcp_user',
  // Add more keys as needed
}

interface LocalStorageService {
  setItem: (key: LocalStorageKeys, value: any) => void;
  getItem: (key: LocalStorageKeys) => any;
  removeItem: (key: LocalStorageKeys) => void;
}

const localStorageService: LocalStorageService = {
  setItem: (key, value) => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error setting item in localStorage:', error);
    }
  },
  getItem: (key) => {
    try {
      const serializedValue = localStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue) : null;
    } catch (error) {
      console.error('Error getting item from localStorage:', error);
      return null;
    }
  },
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing item from localStorage:', error);
    }
  },
};

export default localStorageService;