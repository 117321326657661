import React from 'react';
import EnhancedTable from '../components/tables/BaseTable';

export const Organisasjon = () => {
  return (
    <>
      Organisasjon page
      <EnhancedTable />
    </>
  )
}