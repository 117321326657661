import React from 'react';
import Header from './navigations/Header';


const TopBarLayout = ({ children }: any) => {
  const style = {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "calc(10px + 2vmin)",
  }

  return (
    <>
      <Header />
      <main>
        <div style={style as React.CSSProperties}>
          {children}
        </div>
      </main>
    </>
  );
}

export default TopBarLayout;