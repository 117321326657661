import React, { useState, useRef } from 'react';
import { useApi } from '../../../services/HttpService';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert, AlertColor } from '@mui/material';


export const PersonalData = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const { api: userDataApi, response: userDataResponse, isLoading: userDataIsLoading, error: userDataError } = useApi();
  const { api: saveUserDataApi, response: saveUserDataResponse, isLoading: saveUserDataIsLoading, error: saveUserDataError } = useApi();
  const [isValidEmail2, setEmail2IsValid] = useState(true);
  const [isValidEmail, setEmailIsValid] = useState(true);
  const [isValidFirstName, setFirstNameIsValid] = useState(true);
  const [isValidLastName, setLastNameIsValid] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<AlertColor | undefined>('error');

  const [personalInfo, setPersonalInfo] = useState({
    uid: uid,
    member_number: '',
    first_name: '',
    surname: '',
    co_address: '',
    address_primary: '',
    address_secondary: '',
    postal_code: '',
    post_area: '',
    phonenumber: '',
    email_primary: '',
    email_secondary: '',
    valid: false,
  });

  // const [nameError, setNameError] = useState("");
  // const [emailError, setEmailError] = useState(false);

  React.useEffect(() => {
    const getUserData = async () => {
      await userDataApi("/getPersonalData", "POST", { uid: uid })
    }

    getUserData();
  }, []);

  React.useEffect(() => {
    setPersonalData()
  }, [userDataResponse]);

  const setPersonalData = () => {
    if (userDataResponse?.data) {
      setPersonalInfo({
        ...personalInfo,
        member_number: userDataResponse.data.contact.member_number,
        first_name: userDataResponse.data.contact.first_name,
        surname: userDataResponse.data.contact.last_name,
        address_primary: userDataResponse.data.address.addressline1,
        address_secondary: userDataResponse.data.address.addressline2,
        postal_code: userDataResponse.data.address.postcode,
        post_area: userDataResponse.data.address.postoffice,
        phonenumber: userDataResponse.data.phone.name,
        email_primary: userDataResponse.data.email.name,
        email_secondary: userDataResponse.data.secondary_email_temp,
        co_address: userDataResponse.data.address_co,
      });
    }
  }

  const setDisplayMessage = (message: string, type: AlertColor = 'error') => {
    setMessage(message);
    setMessageType(type);
  }

  const toggleEditMode = () => {
    if (isEditing) {
      setPersonalData();
    }
    setIsEditing(!isEditing);
  };

  const toggleReply = (reply: string) => {
    setResponseMessage(reply)
    setIsResponseReceived(true)
  };

  const SaveUserData = async () => {
    setDisplayMessage('');

    await saveUserDataApi("/SavePersonalData", "POST", { ...personalInfo }).then(res => {
      if (res.statusCode === 200) {
        setDisplayMessage("Persondata er endret", "success")
      } else {
        if (res.error) {
          if (res.error.code === "1") {
            setDisplayMessage("Medlemsnummer mangler");
          } else if (res.error.code === "2") {
            setDisplayMessage("Feil under henting av personopplysninger");
          } else if (res.error.code === "3") {
            setDisplayMessage("Ingen endring");
          } else if (res.error.code === "4") {
            setDisplayMessage("Feil under endringen av personopplysninger, vennligst prøv igjen senere");
          } else if (res.error.code === "5") {
            setDisplayMessage("Feil under lagringen av data, vennligst prøv igjen senere");
          } else {
            setDisplayMessage("feil under oppdatering");
          }
        }
      }
    })
  }

  React.useEffect(() => {
    if (saveUserDataResponse) {
      setDisplayMessage("Persondata er endret", "success")
    }
  }, [saveUserDataResponse])

  const validateEmail = (email: string) => {
    if (email == "")
      return true

    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const ValidateAll = () => {

    var validated = true

    const isEmailValidInput = validateEmail(personalInfo.email_primary);
    setEmailIsValid(isEmailValidInput);
    if (!isEmailValidInput) {
      validated = isEmailValidInput
    }

    const isEmail2ValidInput = validateEmail(personalInfo.email_secondary);
    setEmail2IsValid(isEmail2ValidInput);
    if (!isEmail2ValidInput) {
      validated = isEmail2ValidInput
    }

    return validated
  }

  const saveChanges = () => {

    if (ValidateAll()) {
      SaveUserData();
    }
    setIsEditing(false)
    console.log('ResponseMessage: ', responseMessage);
    console.log('Saving changes:', personalInfo);
  };

  const nameValidator = (value: string) => {
    if (value == "") {
      return true
    }
    // if (value.length < 2) return "Name must be at least 2 characters long";
    // if (value.length > 25) return "Name must be less than 25 characters long";
    if (!/^[a-zA-Z ]+$/.test(value)) {
      return false;
    }
    return true;
  };
  const emailValidator = (value: string) => {
    if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
      return "Invalid email address";
    return false;
  };

  const formValid = useRef({ name: false, email: false });

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (Object.values(formValid.current).every(isValid => isValid)) {
      alert("Form is valid! Submitting the form...");
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        width: 800,
        maxWidth: '100%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
        },
      }}
      noValidate
      autoComplete="off"

    >
      <Typography variant="h6">Personopplysninger</Typography>
      <TextField
        sx={{ width: '25ch' }}
        label="Medlemsnummer"
        value={personalInfo.member_number}
        disabled={true}
        variant="standard"
      />
      <div>
        <TextField
          label="Fornavn"
          required
          value={personalInfo.first_name}
          onChange={(e) => setPersonalInfo({ ...personalInfo, first_name: e.target.value })}
          disabled={!isEditing}
          variant="standard"
        />
        <TextField
          //error={isEditing}
          label="Etternavn"
          required
          value={personalInfo.surname}
          onChange={(e) => setPersonalInfo({ ...personalInfo, surname: e.target.value })}
          //onChange={(e) => validateEmail(e.target.value)}
          disabled={!isEditing}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          sx={{ width: '35ch' }}
          label="c/o adresse"
          value={personalInfo.co_address}
          onChange={(e) => setPersonalInfo({ ...personalInfo, co_address: e.target.value })}
          disabled={!isEditing}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          sx={{ width: '35ch' }}
          label="Adresse 1"
          value={personalInfo.address_primary}
          onChange={(e) => setPersonalInfo({ ...personalInfo, address_primary: e.target.value })}
          disabled={!isEditing}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          sx={{ width: '35ch' }}
          label="Adresse 2"
          value={personalInfo.address_secondary}
          onChange={(e) => setPersonalInfo({ ...personalInfo, address_secondary: e.target.value })}
          disabled={!isEditing}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          sx={{ width: '10ch' }}
          label="Postnr"
          value={personalInfo.postal_code}
          onChange={(e) => setPersonalInfo({ ...personalInfo, postal_code: e.target.value })}
          disabled={!isEditing}
          variant="standard"
          type="number"
        />
        <TextField
          sx={{ width: '13ch' }}
          label="Poststed"
          value={personalInfo.post_area}
          onChange={(e) => setPersonalInfo({ ...personalInfo, post_area: e.target.value })}
          disabled={!isEditing}
          variant="standard"
        />
      </div>
      <div>
        <TextField
          label="Mobiltelefon"
          required
          value={personalInfo.phonenumber}
          onChange={(e) => setPersonalInfo({ ...personalInfo, phonenumber: e.target.value })}
          disabled={!isEditing}
          variant="standard"
          type="number"
        />
      </div>
      <div>
        <TextField
          sx={{ width: '35ch' }}
          label="E-post"
          required
          value={personalInfo.email_primary}
          onChange={(e) => setPersonalInfo({ ...personalInfo, email_primary: e.target.value })}
          disabled={!isEditing}
          variant="standard"
          error={!isValidEmail}
          helperText={!isValidEmail ? 'Ugyldig e-post adresse' : ''}
        />
      </div>
      <div>
        <TextField
          sx={{ width: '35ch' }}
          label="E-post 2"
          value={personalInfo.email_secondary}
          onChange={(e) => setPersonalInfo({ ...personalInfo, email_secondary: e.target.value })}
          disabled={!isEditing}
          variant="standard"
          error={!isValidEmail2}
          helperText={!isValidEmail2 ? 'Ugyldig e-post adresse' : ''}
        />
      </div>
      <div>
        <Button
          onClick={toggleEditMode}
          color="primary"
          variant="contained"
          size="large"
          sx={{ mt: 2 }}
        >
          {isEditing ? 'Avbryt' : 'Endre'}
        </Button>
        {isEditing && (
          <Button
            onClick={saveChanges}
            color="primary"
            variant="contained"
            size="large"
            sx={{ mt: 2, ml: 2 }}
          >
            Lagre
          </Button>
        )}
      </div>
      <Box sx={{ mt: '15px', width: '50ch' }}>
        {message && <Alert severity={messageType}>{message}</Alert>}
      </Box>
    </Box>
  );
};