import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

export const MissingPermission = () => {
  const navigate = useNavigate();

  return (
    <>
      <h3>Du har ikke tilgang til denne siden</h3>
      <a
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer", fontSize: "1rem" }}
      >
        Gå tilbake
      </a>
    </>
  )
}