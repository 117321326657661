import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, SxProps, Theme } from '@mui/material';

interface FallbackImageProps {
  primarySrc: string;
  secondarySrc: string;
  altText: string;
  width?: string;
  height?: string;
  containerStyle?: SxProps<Theme>;
}

const FallbackImage = (props: FallbackImageProps) => {
  const [currentSrc, setCurrentSrc] = useState(props.primarySrc);
  const [isLoading, setIsLoading] = useState(true);
  var width = props.width;
  var height = props.height;

  if (!props.width) {
    width = "100%";
  }
  if (!props.height) {
    height = "100%";
  }

  // console.log("localSrc:", props.secondarySrc)

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setCurrentSrc(props.primarySrc);
      setIsLoading(false);
    };
    img.onerror = () => {
      setCurrentSrc(props.secondarySrc);
      setIsLoading(false);
    };
    img.src = props.primarySrc;
  }, [props.primarySrc, props.secondarySrc]);

  return (
    <Box sx={props.containerStyle}>
      {isLoading ? (
        <Box sx={{ width: width, height: height }}></Box>
      ) : (
        <img
          src={currentSrc}
          alt={props.altText}
          style={{
            maxWidth: width,
            maxHeight: width,
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
        />
      )}
    </Box>
  );
};

FallbackImage.propTypes = {
  primarySrc: PropTypes.string.isRequired,
  secondarySrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default FallbackImage;