import { Alert, Box, CircularProgress, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { InfoArea } from '../../components/ui/InfoArea';
import { useApi } from '../../services/HttpService';

interface FieldInfo {
  name: string;
  datatype: string;
  auto_increment: boolean;
  allow_null: boolean;
  default_value: string;
  primary_key: boolean;
}

interface WrongTabelFields {
  database_field: FieldInfo;
  sys_field: FieldInfo;
}

interface TabelFields {
  [table_name: string]: FieldInfo[];
}

interface WrongTabel {
  [table_name: string]: WrongTabelFields[];
}

interface DatabaseState {
  missing_tables: string[];
  extra_tables: string[];
  missing_fields: TabelFields;
  extra_fields: TabelFields;
  wrong_fields: WrongTabel;
}


interface TableAreaProps {
  tableName: string;
  rows: FieldInfo[];
}

export function TableArea(props: TableAreaProps) {
  const { tableName, rows } = props;

  return (
    <Box>
      <Typography variant='h6'>{tableName}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} size="small" aria-label="database states">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Datatype</TableCell>
              <TableCell align="right">Auto inc.</TableCell>
              <TableCell align="right">Allow null</TableCell>
              <TableCell>Default</TableCell>
              <TableCell align="right">PK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.datatype}</TableCell>
                <TableCell align="right">{row.auto_increment ? "true" : "false"}</TableCell>
                <TableCell align="right">{row.allow_null ? "true" : "false"}</TableCell>
                <TableCell>{row.default_value}</TableCell>
                <TableCell align="right">{row.primary_key ? "true" : "false"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const DatabaseState = () => {
  const { api, error, isLoading } = useApi();
  const [databaseState, setDatabaseState] = useState<DatabaseState>({} as DatabaseState);

  useEffect(() => {
    api("/databaseState").then(res => {
      if (res.statusCode === 200) {
        setDatabaseState(res);
      }
    });
  }, [])

  useEffect(() => {
    console.log("databaseState:", databaseState.extra_tables)
  }, [databaseState])

  return (
    <>
      <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, width: '100%', maxWidth: { xs: 512, md: 768 }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2, p: 2 }}>
        <Typography variant='h4' sx={{ mt: 2 }}>Database Status</Typography>
        {isLoading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="info" />
          </Box>
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            <InfoArea title="Table states">
              {(databaseState?.missing_tables?.length > 0 || databaseState?.extra_tables?.length > 0) ? (
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, flexWrap: "wrap" }}>
                  <Box sx={{ p: 2, width: { xs: "100%", md: 340 }, border: "1px solid black" }}>
                    <Typography variant='h6' sx={{ mt: 2 }}>Missing tables:</Typography>
                    <List dense>
                      {databaseState?.missing_tables?.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={item}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ p: 2, width: { xs: "100%", md: 340 }, border: "1px solid black" }}>
                    <Typography variant='h6' sx={{ mt: 2 }}>Extra tables:</Typography>
                    <List dense>
                      {databaseState?.extra_tables?.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={item}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              ) : (
                <Alert>Tables up to date</Alert>
              )}
            </InfoArea>
            <InfoArea title="Missing fields">
              {Object.keys(databaseState?.missing_fields).length > 0 ? (
                <>
                  {Object.keys(databaseState?.missing_fields).map((tableName) => (
                    <TableArea key={tableName} tableName={tableName} rows={databaseState.missing_fields[tableName]} />
                  ))}
                </>
              ) : (
                <Alert>No missing fields</Alert>
              )}
            </InfoArea>
            <InfoArea title="Extra fields">
              {Object.keys(databaseState?.extra_fields).length > 0 ? (
                <>
                  {Object.keys(databaseState?.extra_fields).map((tableName) => (
                    <TableArea key={tableName} tableName={tableName} rows={databaseState.extra_fields[tableName]} />
                  ))}
                </>
              ) : (
                <Alert>No extra fields</Alert>
              )}
            </InfoArea>
            <InfoArea title="Wrong fields">
              {Object.keys(databaseState?.wrong_fields).length > 0 ? (
                <>
                  {Object.keys(databaseState?.wrong_fields).map((tableName) => (
                    <Box key={tableName}>
                      <Typography variant='h6'>{tableName}</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ width: "100%" }} size="small" aria-label="database states">
                          <TableHead>
                            <TableRow key={tableName}>
                              <TableCell></TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Datatype</TableCell>
                              <TableCell align="right">Auto inc.</TableCell>
                              <TableCell align="right">Allow null</TableCell>
                              <TableCell>Default</TableCell>
                              <TableCell align="right">PK</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {databaseState.wrong_fields[tableName].map((field, index) => (
                              <>
                                <TableRow
                                  key={"have_" + field.database_field.name}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { pt: index > 0 ? 4 : 1, alignContent: "end" } }}
                                >
                                  <TableCell>Database</TableCell>
                                  <TableCell component="th" scope="row">{field.database_field.name}</TableCell>
                                  <TableCell>{field.database_field.datatype}</TableCell>
                                  <TableCell align="right">{field.database_field.auto_increment ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.database_field.allow_null ? "true" : "false"}</TableCell>
                                  <TableCell>{field.database_field.default_value}</TableCell>
                                  <TableCell align="right">{field.database_field.primary_key ? "true" : "false"}</TableCell>
                                </TableRow>
                                <TableRow
                                  key={"want_" + field.database_field.name}
                                  sx={{ '& td': { border: 0 } }}
                                >
                                  <TableCell>System</TableCell>
                                  <TableCell component="th" scope="row">{field.sys_field.name}</TableCell>
                                  <TableCell>{field.sys_field.datatype}</TableCell>
                                  <TableCell align="right">{field.sys_field.auto_increment ? "true" : "false"}</TableCell>
                                  <TableCell align="right">{field.sys_field.allow_null ? "true" : "false"}</TableCell>
                                  <TableCell>{field.sys_field.default_value}</TableCell>
                                  <TableCell align="right">{field.sys_field.primary_key ? "true" : "false"}</TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ))}
                </>
              ) : (
                <Alert>No wrong fields</Alert>
              )}
            </InfoArea>
          </>
        )}
      </Paper>
    </>
  )
}