import React, { useEffect } from "react";
import { AppRouter } from "./routes/AppRouter";
import BaseLayout from "./components/layouts/BaseLayout";
import { create } from "zustand";
import "./App.css";
import "./pages/public/poster/components/i18n";
import { AuthService } from "./services/AuthService";
import { useApi } from "./services/HttpService";
import { themeStore } from "./components/themes/BaseTheme";

export const authStore = create<{
  isLoggedin: boolean;
  initialLoad: boolean;
  userName: string;
  userRoles: string[];
}>((setState, getState) => ({
  isLoggedin: false,
  initialLoad: false,
  userName: "",
  userRoles: [],
}));

export const tenantStore = create<{
  name: string;
  displayName: string;
}>((setState, getState) => ({
  name: "",
  displayName: "",
}));

function App() {
  const { authenticate, logout } = AuthService();
  const { api } = useApi();

  useEffect(() => {
    authenticate().then(success => {
      if (!success) {
        logout();
      }

      authStore.setState({ initialLoad: true });
    });
  }, []);

  useEffect(() => {
    api("/getInfo", "GET").then((res) => {
      if (res?.statusCode === 200) {
        let pal = res.palette;
        themeStore.getState().updateColors(pal.primary, pal.secondary, pal.success);
        tenantStore.setState({ name: res.name, displayName: res.displayName });
      }
    })
  }, []);

  return (
    <BaseLayout>
      <AppRouter />
    </BaseLayout>
  );
}

export default App;
