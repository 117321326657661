import React, { useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useApi } from '../services/HttpService';

export const Home = () => {
  return (
    <>
      <h4>
        Home page
      </h4>
    </>
  )
}